import React from "react";
import { fetchPublicTrainingCertificateDetails } from "../../actions/trainingActions";
import Loader from "../Loader/Loader";
import "./PublicTrainingCertificateDetailsPage.scss";
import TextWithIconAndDescription from "./TextWithIconAndDescription";
import { Text } from "../../../../shared-components/my-scope/atoms/text"; // Import the CSS file

class PublicTrainingCertificateDetailsPage extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      trainingCertificateDetails: null,
      isLoading: true,
      error: null,
    };
  }

  componentDidMount() {
    this.loadTrainingCertificateDetails();
  }

  loadTrainingCertificateDetails = async () => {
    const { training_id } = this.props.match.params;
    try {
      const response = await fetchPublicTrainingCertificateDetails(training_id);

      if (response.ok) {
        const val = await response.json();
        this.setState({ trainingCertificateDetails: val, isLoading: false });
      } else {
        this.setState({ error: "التدريب غير معتمد", isLoading: false });
      }
    } catch (error) {
      console.error("Error fetching training certificate details:", error);
      this.setState({ error: "فشل في تحميل التفاصيل", isLoading: false });
    }
  };

  render() {
    const { trainingCertificateDetails, isLoading, error } = this.state;

    if (isLoading) {
      return (
        <div className="loader-wrapper">
          <Loader />
        </div>
      );
    }

    if (error) {
      return <div className="error-message">{error}</div>;
    }

    if (!trainingCertificateDetails) {
      return (
        <div className="error-message">لا توجد تفاصيل لشهادة التدريب.</div>
      );
    }

    const { course_name, hours, certificate_granted_at } =
      trainingCertificateDetails;
    const formattedDate =
      new Date(certificate_granted_at).toLocaleDateString() !== "1/1/1970"
        ? new Date(certificate_granted_at).toLocaleDateString()
        : "لم تصدر";

    return (
      <div className="public-training">
        <div className="training-course-details-bill-wrapper">
          <div className="training-course-details-white-box">
            <div className="training-box-top-title">
              <Text
                Tag="div"
                textType="p3"
                className="training-course-details-title training-box-top-title__name"
              >
                نتيجة المسح
              </Text>
            </div>

            <div className="training-course-details-top-margin ">
              <Text
                Tag="div"
                textType="p4"
                isBold
                className="training-course-details-title "
              >
                تفاصيل الشهادة
              </Text>
            </div>

            <div className="training-course-details-top-margin ">
              <TextWithIconAndDescription
                description="برنامج التقييم الذاتي"
                icon="img/all.svg"
                iconHeight="25px"
                title="اسم مقدم الخدمة"
                textType="p5"
              />
            </div>

            <div className="training-course-details-top-margin ">
              <TextWithIconAndDescription
                description={`${course_name}`}
                icon="img/certificate.svg"
                iconHeight="25px"
                title="اسم الدورة التدريبية"
                textType="p5"
              />
            </div>

            <div className="training-course-details-top-margin ">
              <TextWithIconAndDescription
                description={`${hours} ساعة`}
                icon="img/clock-2.svg"
                iconHeight="25px"
                title="عدد الساعات"
                textType="p5"
              />
            </div>

            <div className="training-course-details-top-margin ">
              <TextWithIconAndDescription
                description={`${formattedDate}`}
                icon="img/clock-1.svg"
                iconHeight="25px"
                title="تاريخ اصدار الشهادة"
                textType="p5"
              />
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default PublicTrainingCertificateDetailsPage;
