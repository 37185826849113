import React from "react";
import { Card, Typography } from "@material-ui/core";
import { ANSWERS, ANSWER_EMPTY } from "../../common/constants/caliberAnswers";
import {
  NoIcon,
  YesIcon,
} from "../../../../frontend-establishment/src/components/establishmentprofile/PrevAnswers";
import {
  ANSWER_ACTIVE,
  ANSWER_COMPLIANT,
  ANSWER_NOT_COMPLIANT,
  Not_Active,
} from "../../../../frontend-establishment/src/common/constants/caliberAnswers";
import { Link } from "@mui/material";
import "./qiwaCaliberCard.scss";
import { Text } from "../../../../shared-components/my-scope/atoms/text";
import "./qiwaCaliberCard.scss";

export const QiwaCaliberCard = ({ items }) => {
  const filteredItems =
    items?.filter((item) => item?.integration_service_value?.length > 0) ?? [];
  return (
    <Card className="qiwa-card ">
      <Text
        Tag={"div"}
        textType="bold"
        fontSize={"font-20"}
        mobileFontSize={"font-16-mobile"}
        style={{
          color: "#14415A",
        }}
      >
        {"البنود المؤتمتة"}
      </Text>

      <Text
        Tag={"div"}
        textType="roman"
        fontSize={"font-20"}
        mobileFontSize={"font-16-mobile"}
        style={{
          color: "#505050",
          fontWeight: "400",
          textAlign: "justify",
        }}
      >
        {
          "يحتوي هذا الجزء من النموذج على جميع البنود التي تم الحصول على إجاباتها من خلال منصة قوى، في حال رغبتك برفع التزامك بالبند يرجى زيارة المنصة من خلال الرابط التالي"
        }{" "}
        <Link
          href="https://www.qiwa.sa/ar/"
          sx={{
            marginInline: "1px",
            color: "#148287",
            textDecoration: "underline",
            ":hover": { color: "#148287" },
          }}
          target="_blank"
        >
          <Text
            Tag={"div"}
            textType="roman"
            fontSize={"font-20"}
            mobileFontSize={"font-16-mobile"}
            style={{
              fontWeight: "400",
            }}
          >
            {"منصة قوى"}
          </Text>
        </Link>
      </Text>

      <div
        style={{
          display: "flex",
          flexDirection: "column",
          rowGap: 32,
          marginTop: 35,
        }}
      >
        {filteredItems?.map?.((item, idx) => (
          <CardItem key={idx} item={item} />
        ))}
      </div>
    </Card>
  );
};

// Card Item
const CardItem = ({ item }) => {
  // console.log("item", item);
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        minHeight: 256,
        paddingBottom: 16,
      }}
    >
      <div className="qiwa-caliber-name">
        <Text
          Tag={"div"}
          textType="roman"
          fontSize={"font-20"}
          mobileFontSize={"font-16-mobile"}
          style={{ color: "#000" }}
        >
          {item?.caliber_name}
        </Text>
      </div>

      <div className="qiwa-caliber">
        <Text
          Tag={"div"}
          textType="roman"
          fontSize={"font-18"}
          mobileFontSize={"font-16-mobile"}
          style={{ color: "#000" }}
        >
          {"هل تلتزم المنشأة بالبند المذكور أعلاه؟"}
        </Text>

        <div className="qiwa-answer ">
          <Text
            Tag={"div"}
            textType="roman"
            fontSize={"font-18"}
            mobileFontSize={"font-16-mobile"}
          >
            {item?.integration_service_value === ANSWER_NOT_COMPLIANT && "لا"}
            {item?.integration_service_value === Not_Active && "لا"}
            {item?.integration_service_value === ANSWER_ACTIVE && "نعم"}
            {item?.integration_service_value === ANSWER_COMPLIANT && "نعم"}
            {item?.integration_service_value == null && ANSWERS[ANSWER_EMPTY]}
          </Text>
          {item?.integration_service_value === ANSWER_NOT_COMPLIANT && (
            <NoIcon fill={"#F5961E"} />
          )}
          {item?.integration_service_value === ANSWER_ACTIVE && (
            <YesIcon fill={"#F5961E"} />
          )}
        </div>
      </div>
      {!!item?.commitment_percentage && (
        <Text
          Tag={"div"}
          textType="roman"
          fontSize={"font-18"}
          mobileFontSize={"font-16-mobile"}
          className={"qiwa-commitment-percentage"}
        >
          {"- "} {" نسبة الالتزام بتوثيق العقود "} {item?.commitment_percentage}{" "}
          {"%"}
        </Text>
      )}
      <div className="qiwa-caliber-info">
        <Typography
          style={{
            fontFamily: "Effra",
            fontSize: "18px",
            lineHeight: "16px",
            color: "#14415A",
          }}
        >
          {" * "}
        </Typography>
        <Text
          Tag={"div"}
          textType="roman"
          fontSize={"font-18"}
          mobileFontSize={"font-16-mobile"}
          style={{ color: "#14415A", textAlign: "justify" }}
        >
          {
            " تم الحصول على هذه الإجابة من خلال منصة قوى، في حال رغبتك لرفع التزامك بالبند يرجى زيارة المنصة من خلال الرابط التالي "
          }{" "}
          <Link
            href="https://www.qiwa.sa/ar/"
            sx={{
              color: "#148287",
              textDecoration: "underline",
              ":hover": { color: "#148287" },
              marginInline: "4px",
            }}
            target="_blank"
          >
            {"منصة قوى"}
          </Link>
        </Text>
      </div>
    </div>
  );
};
