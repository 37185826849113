import React, { useEffect } from "react";
import QRCode from "qrcode.react";
import MOLLogo from "../../../public/img/MHRSD-Logo-certificate.svg";
import IMSLogo from "../../../public/img/IMS-Logo-certificate.svg";
import "./trainingCertificateView.scss";
import { PdfTrainingCertificate } from "../../../../shared-components/my-scope/pages/pdf-training-certificate";
import { Text } from "../../../../shared-components/my-scope/atoms/text";

const TrainingCertificateView = (props) => {
  useEffect(() => {
    document.body.classList.add("noscroll");
  }, []);
  const { location } = props;
  const { search } = location;
  let establishmentName;
  let trainingSubjectName;
  let trainingId;
  const searchParams = new URLSearchParams(search);

  if (searchParams.has("establishmentName")) {
    establishmentName = searchParams.get("establishmentName");
    searchParams.delete("establishmentName");
  }
  if (searchParams.has("trainingSubjectName")) {
    trainingSubjectName = searchParams.get("trainingSubjectName");
    searchParams.delete("trainingSubjectName");
  }
  if (searchParams.has("trainingId")) {
    trainingId = searchParams.get("trainingId");
    searchParams.delete("trainingId");
  }
  const print = () => {
    PdfTrainingCertificate(establishmentName, trainingSubjectName);
  };

  const { isLoading } = props;

  if (isLoading) {
    return null;
  }

  return (
    <div className="certificate">
      <header className="certificate-menu doNotPrint">
        <div className="certificate-menu-group">
          <div className="certificate-file">
            <i className="fa fa-file-o certificate-file__icon"></i>
            <Text Tag="span" textType="h9">
              شهادة حضور
            </Text>
          </div>
        </div>
        <div className="certificate-menu-group">
          <div onClick={print} className="certificate-download">
            <i className="fa fa-download certificate-download__icon"></i>
            <Text Tag="span" textType="h9">
              تحميل
            </Text>
          </div>
        </div>
      </header>
      <div className="certificate-window">
        <div className="certificate-content-wrapper">
          <div className="certificate-content">
            <header className="certificate-header">
              <img
                src={MOLLogo}
                alt="MOL Logo"
                className="certificate-mol-logo"
              />
              <img
                src={IMSLogo}
                alt="IMS Logo"
                className="certificate-ims-logo"
              />
            </header>
            <main className="certificate-main">
              <Text
                className="certificate-header-text"
                textType="bold"
                Tag="div"
                fontSize={"font-30"}
              >
                شهادة حضور
              </Text>

              <Text
                textType="roman"
                Tag="div"
                className="certificate-text"
                fontSize={"font-22"}
              >
                تمنح وزارة الموارد البشرية والتنمية الاجتماعية هذه الشهادة لـ
              </Text>
              <Text
                textType="bold"
                Tag="div"
                className="certificate-company-name"
                fontSize={"font-22"}
              >
                {establishmentName}
              </Text>
              <Text
                textType="roman"
                Tag="div"
                className="certificate-text"
                fontSize={"font-22"}
              >
                {`وذلك لإنهائها برنامج ${trainingSubjectName} التدريبي `}
              </Text>
            </main>
            <footer className="certificate-footer">
              <div className="certificate-qrcode">
                <QRCode
                  value={`${process.env.REACT_APP_API_URI}/#/public/training/certificate/${trainingId}`}
                  size={64}
                  bgColor={"#fff"}
                  fgColor={"#000"}
                  level={"L"}
                  includeMargin={false}
                  renderAs={"png"}
                  id="qrCodeTraining"
                />
                <Text
                  textType="roman"
                  className="certificate-small-text"
                  fontSize={"font-10"}
                >
                  التحقق من صحة الشهادة
                </Text>
              </div>
            </footer>
          </div>
        </div>
      </div>
    </div>
  );
};

export default TrainingCertificateView;
